import React from "react"
import styled from "styled-components"
//import logo from "../images/logo_white.png"
import { Link } from "gatsby"
import { Logo } from "./svg/Logo"

const Wrapper = styled.nav`
  color: white;
  width: 100%;
  padding: 24px;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const Links = styled.div`
  display: flex;
  flex-direction: row;
`

const MenuLink = styled(Link)`
  display: block;
  color: inherit;
  font-family: "Montserrat", sans-serif;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
  font-size: 15px;
  font-weight: 500;
  min-width: 100px;
  @media screen and (min-width: 1000px) {
    min-width: 150px;
  }
`

export const Navbar = ({style}) => {
  return (
    <Wrapper style={style}>
      <Link to={'/'}>
        <Logo color="white" strikeColor="white" width="129px" height="38px"/>
      </Link>
      <Links>
        <MenuLink to="https://play.google.com/store/apps/details?id=no.ways.justdrive">
          Last ned
        </MenuLink>
        <MenuLink to={'/hjelp'}>Hjelp</MenuLink>
        <MenuLink to={'/personvern'}>Personvern</MenuLink>
      </Links>
    </Wrapper>
  )
}
